import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import styled from "styled-components";
import {
  successStrong,
  errorStrong,
  undeterminedStrong,
} from "../styles/colors";
import {
  loadDashboard,
  changeArchive,
  setArchiveSetting,
  setProgressSearchTerm,
  setProgressFrom,
  setProgressTo,
  setProgressInside,
  setProgressBorderline,
  setProgressOutside,
  saveEngagement,
} from "../store/assessment/actions";
import {
  getDashboardData,
  getDashboardLoading,
  getArchiveSetting,
  getProgressSearchTerm,
  getProgressFrom,
  getProgressTo,
  getProgressInside,
  getProgressBorderline,
  getProgressOutside,
} from "../store/assessment/selectors";
import {
  getCompanyType,
  getHasFeature,
  getUserCompany,
} from "../store/users/selectors";
import {
  faAsterisk,
  faBalanceScaleLeft,
  faCheck,
  faClock,
  faEye,
  faFileAlt,
  faPaperPlane,
  faArrowRight,
  faUserFriends,
  faClone,
  faArchive,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { loadClients, setCurrentClient } from "../store/clients/actions";
import { getClients, getCurrentClient } from "../store/clients/selectors";
import { TitleArea } from "../components/Elements";
import Loader from "../components/Loader";
import { generateClientOptions } from "./Contractors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getDisplayName, getRiskClassName } from "../components/ResultScreen";
import FormField from "../components/FormField";
import Combo from "../components/Combo";

export const statuses = {
  STARTED: {
    name: "Started",
    icon: faAsterisk,
  },
  AWAITING_CONTRACTOR: {
    name: "Awaiting Contractor",
    icon: faClock,
  },
  AWAITING_REVIEW: {
    name: "Awaiting Review",
    icon: faEye,
  },
  AWAITING_DISTRIBUTION: {
    name: "SDS Ready to send",
    icon: faPaperPlane,
  },
  AWAITING_SDS_ACCEPTANCE: {
    name: "Waiting for SDS",
    icon: faFileAlt,
  },
  IN_DISPUTE: {
    name: "In Dispute",
    icon: faBalanceScaleLeft,
  },
  COMPLETE: {
    name: "Complete",
    icon: faCheck,
  },
};

const ClientSelector = styled.select`
  margin-top: 20px;
  margin-left: 30px;
  height: 39px;
  font-size: 16px;
  outline: none;
  border: 1px solid #ccc;
`;

const ColumnsWrapper = styled.div`
  display: flex;
  box-sizing: border-box;
  position: relative;
  flex: 1;
  .hint {
    transition: opacity 150ms ease-in;
    opacity: 0;
    @media (max-width: 1980px) {
      opacity: 1;
    }
    position: absolute;
    top: -40px;
    right: 40px;
  }
`;

const Columns = styled.div`
  display: flex;
  box-sizing: border-box;
  flex: 1
  margin: 20px 30px;
  max-height: 100%;
  overflow: auto;
  position: relative;
  transition: opacity 300ms ease-in;
  

  ${(props) => (props.loading ? "opacity: 0.4" : "opacity: 1")}
  
`;

const Column = styled.div`
  align-self: flex-start;
  flex: 1;
  padding: 10px;
  min-width: 275px;
  background-color: #e6e6e6;
  margin: 10px;
  border-radius: 4px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 430px);
  transition: height 200ms ease-in;

  &:first-child {
    margin-left: 0px;
  }

  &:last-child {
    margin-right: 0px;
  }

  h2 {
    margin: 0;
    font-size: 16px;
    padding: 5px;
    color: #414141;
    margin-bottom: 10px;
    position: relative;
    padding-right: 25px;
  }

  .card-area {
    overflow: auto;
  }
`;

const Main = styled.div`
  display: flex;
  height: 100%;
`;

const Wrap = styled.div`
  flex-direction: column;
  width: 100%;
  display: flex;
`;

const Card = styled.div`
  position: relative;
  border-radius: 4px;
  background-color: #fff;
  padding: 10px;
  margin-bottom: 10px;
  color: #414141;
  display: flex;
  box-shadow: 0px 3px 4px -2px rgba(0, 0, 0, 0.75);

  .left {
    width: 30px;
    font-size: 20px;
  }

  .right {
    flex: 1;
  }

  .reference {
    font-size: 12px;
  }

  h3 {
    margin: 0;
    font-size: 16px;
    color: #414141;
    ${(props) => (props.archived ? "color: #ccc;" : "")}
    margin-top: 6px;
  }
  cursor: pointer;
  transition: border-left-width 200ms ease-in;

  border-left: 4px solid #ccc;

  &.pass {
    border-left: 4px solid ${successStrong};
  }

  &.fail {
    border-left: 4px solid ${errorStrong};
  }

  &.undetermined {
    border-left: 4px solid ${undeterminedStrong};
  }

  &:hover {
    border-left-width: 10px;
  }

  ${(props) => (props.flag ? "background-color: #fdf5dd;" : "")}
  ${(props) => (props.archived ? "color: #ccc;" : "")}
`;

const EmojiIconFloat = styled.div`
  position: absolute;
  top: 10px;
  right: 5px;
  font-size: 16px;
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  margin-right: 5px;

  &.flag {
    color: #b49229;
  }
`;

const FlagText = styled.div`
  font-size: 12px;
  text-transform: uppercase;
  color: #b49229;
`;

const Archive = styled.div`
  position: absolute;
  top: 4px;
  right: 4px;
  color: #000;
  font-size: 12px;
  text-transform: uppercase;
  background-color: #ddd;
  padding: 3px;
  border-radius: 2px;

  transition: background-color 100ms ease-in, color 100ms ease-in;

  &:hover {
    background-color: #444;
    color: #fff;
  }
`;

const Delete = styled.div`
  position: absolute;
  bottom: 4px;
  right: 4px;
  color: #000;
  font-size: 12px;
  text-transform: uppercase;
  background-color: #ddd;
  padding: 3px;
  border-radius: 2px;

  transition: background-color 100ms ease-in, color 100ms ease-in;

  &:hover {
    background-color: #444;
    color: #fff;
  }
`;

const ArchiveIcon = styled(FontAwesomeIcon)`
  margin-left: 3px;
`;

const DeleteIcon = styled(FontAwesomeIcon)``;

const Filter = styled.div`
  margin: 20px 30px 0px;

  h2 {
    margin-top: 0;
    margin-bottom: 0;
  }
`;

const Result = styled.div`
  &.pass {
    background-color: ${successStrong};
  }

  &.fail {
    background-color: ${errorStrong};
  }

  &.undetermined {
    background-color: ${undeterminedStrong};
  }

  &.generic {
    background-color: #888;
  }

  padding: 3px;
  text-transform: uppercase;
  color: #fff;
  display: inline-block;
  font-size: 12px;
  margin-right: 2px;
`;

const FormRow = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

const FilterItem = styled.div`
  background-color: #eee;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 4px;
  margin-right: 10px;

  &.search {
    flex-basis: 400px;
  }
`;

const StyledCombo = styled(Combo)`
  margin-bottom: 20px;
  margin-top: 20px;
  margin-left: 30px;
`;

function Dashboard({ history }) {
  const dispatch = useDispatch();

  const dashboardData = useSelector(getDashboardData);
  const userCompany = useSelector(getUserCompany);
  const currentClient = useSelector(getCurrentClient);
  const company = useSelector(getUserCompany);
  const dashboardLoading = useSelector(getDashboardLoading);

  const archiveSetting = useSelector(getArchiveSetting);

  const searchTerm = useSelector(getProgressSearchTerm);

  const setSearchTerm = (term) => {
    dispatch(setProgressSearchTerm(term));
  };

  const [reportingDataToRender, setReportingDataToRender] = useState([]);

  const showInside = useSelector(getProgressInside);

  const setShowInside = (setting) => {
    dispatch(setProgressInside(setting));
  };

  const showBorderline = useSelector(getProgressBorderline);

  const setShowBorderline = (setting) => {
    dispatch(setProgressBorderline(setting));
  };
  const showOutside = useSelector(getProgressOutside);

  const setShowOutside = (setting) => {
    dispatch(setProgressOutside(setting));
  };

  const fromDate = useSelector(getProgressFrom);

  const setFromDate = (setting) => {
    dispatch(setProgressFrom(setting));
  };

  const toDate = useSelector(getProgressTo);

  const setToDate = (setting) => {
    dispatch(setProgressTo(setting));
  };

  const reports = dashboardData && dashboardData.reports;

  const clients = useSelector(getClients);
  let clientOptions = [];
  if (clients) {
    clientOptions = generateClientOptions(clients, userCompany);
  }

  useEffect(() => {
    dispatch(loadClients());
  }, []);

  useEffect(() => {
    if (userCompany && !currentClient) {
      dispatch(setCurrentClient(userCompany.id));
    }
  }, [userCompany]);

  useEffect(() => {
    console.log("TELLING TO LOAD");
    dispatch(
      loadDashboard(
        currentClient === 0 ? company.id : currentClient,
        archiveSetting
      )
    );
  }, [currentClient, archiveSetting]);


  const deleteAssessment = (engagement_id) => {
    const options = {
      title: "Are you sure?",
      message:
        "Are you sure you want to delete this engagement? This cannot be reversed!",
      buttons: [
        {
          label: "Cancel",
        },
        {
          label: "Confirm",
          onClick: async () => {
            await dispatch(
              saveEngagement({
                id: engagement_id,
                hidden: true,
              })
            );
            await dispatch(
              loadDashboard(
                currentClient === 0 ? company.id : currentClient,
                archiveSetting
              )
            );
          },
        },
      ],
    };
    confirmAlert(options);
  };

  useEffect(() => {
    const searchTermLower = searchTerm ? searchTerm.toLowerCase() : null;

    if (reports) {
      setReportingDataToRender(
        reports.filter((report) => {
          const nameMatch =
            report.contractor_name.toLowerCase().indexOf(searchTermLower) > -1;

          let test = true;

          if (searchTerm) {
            test = test && nameMatch;
          }

          const isInside =
            report.result === "BAD_FAIL" || report.result === "FAIL";

          if (showInside && isInside) {
            test = test && isInside;
          }

          if (!showInside && isInside) {
            test = test && !isInside;
          }

          const isBorderline = report.result === "BORDERLINE";

          if (showBorderline && isBorderline) {
            test = test && isBorderline;
          }

          if (!showBorderline && isBorderline) {
            test = test && !isBorderline;
          }

          const isOutside =
            report.result === "PASS" || report.result === "GOOD_PASS";

          if (showOutside && isOutside) {
            test = test && isOutside;
          }

          if (!showOutside && isOutside) {
            test = test && !isOutside;
          }

          if (fromDate) {
            test = test && new Date(report.datetime) >= new Date(fromDate);
          }

          if (toDate) {
            test = test && new Date(report.datetime) <= new Date(toDate);
          }

          return test;
        })
      );
    }
  }, [
    searchTerm,
    reports,
    showInside,
    showBorderline,
    showOutside,
    fromDate,
    toDate,
  ]);

  const getByStatus = (status) => {
    const filtered = reportingDataToRender
      ? reportingDataToRender.filter((report) => report.status === status)
      : [];

    if (status === "IN_DISPUTE") {
      return filtered.sort((x, y) => {
        if (x.flag && !y.flag) {
          return -1;
        }
        if (!x.flag && y.flag) {
          return 1;
        }
        return 0;
      });
    }
    if (status === "COMPLETE") {
      return filtered.sort((x, y) => {
        if (x.archived && !y.archived) {
          return 1;
        }
        if (!x.archived && y.archived) {
          return -1;
        }
        return 0;
      });
    }
    return filtered;
  };

  return (
    <Main>
      {!dashboardData ? (
        <Loader />
      ) : (
        <Wrap>
          <div>
            <TitleArea>
              <div className="title">
                <h1>Progress</h1>
              </div>
            </TitleArea>
            {clients && clients.length ? (
              <StyledCombo
                options={clientOptions}
                value={currentClient}
                onChange={(newValue) => {
                  dispatch(setCurrentClient(newValue));
                }}
              />
            ) : null}
            <Filter>
              <FormRow>
                <FilterItem className="search">
                  <FormField
                    placeholder="Search"
                    type="text"
                    changeMonitor={(value) => setSearchTerm(value)}
                    className="search"
                    value={searchTerm}
                    label="Search by keyword"
                  />
                </FilterItem>
                <FilterItem>
                  <FormField
                    placeholder="From"
                    type="date"
                    changeMonitor={(value) => setFromDate(value)}
                    value={fromDate}
                    label="From"
                  />
                </FilterItem>
                <FilterItem>
                  <FormField
                    placeholder="To"
                    type="date"
                    changeMonitor={(value) => setToDate(value)}
                    value={toDate}
                    label="To"
                  />
                </FilterItem>
              </FormRow>
              <FormRow>
                <FilterItem>
                  <input
                    type="checkbox"
                    id="show-archived"
                    defaultChecked={archiveSetting}
                    value={archiveSetting}
                    onChange={(e) =>
                      dispatch(setArchiveSetting(e.target.checked))
                    }
                  />
                  <label for="show-archived">Show Archived</label>
                </FilterItem>
                <FilterItem>
                  <input
                    type="checkbox"
                    id="inside"
                    defaultChecked={showInside}
                    value={showInside}
                    onChange={(e) => setShowInside(e.target.checked)}
                  />
                  <label for="inside">Show Inside IR35</label>
                </FilterItem>
                <FilterItem>
                  <input
                    type="checkbox"
                    id="borderline"
                    defaultChecked={showBorderline}
                    value={showBorderline}
                    onChange={(e) => setShowBorderline(e.target.checked)}
                  />
                  <label for="borderline">Show Borderline</label>
                </FilterItem>
                <FilterItem>
                  <input
                    type="checkbox"
                    id="outside"
                    defaultChecked={showOutside}
                    value={showOutside}
                    onChange={(e) => setShowOutside(e.target.checked)}
                  />
                  <label for="outside">Show Outside IR35</label>
                </FilterItem>
              </FormRow>
            </Filter>
          </div>
          <ColumnsWrapper>
            <div className="hint">
              Scroll for more <StyledFontAwesomeIcon icon={faArrowRight} />
            </div>
            <Columns loading={dashboardLoading}>
              {Object.keys(statuses).map((key) => (
                <Column>
                  <h2>
                    <EmojiIconFloat>
                      <StyledFontAwesomeIcon icon={statuses[key].icon} />
                    </EmojiIconFloat>
                    {statuses[key].name}
                  </h2>
                  <div className="card-area">
                    {getByStatus(key).map((record) => (
                      <Card
                        flag={record.flag}
                        className={[
                          record.contractor_id === -1 ? "role" : "contractor",
                          getRiskClassName(record.result),
                        ]}
                        archived={record.archived}
                        onClick={() => {
                          history.push(
                            `/main/contractors/assessment/${
                              record.company_id
                            }/${
                              record.contractor_id === -1
                                ? "rolebased"
                                : record.contractor_id
                            }/${record.engagement_id}`
                          );
                        }}
                      >
                        <Archive
                          onClick={(e) => {
                            e.stopPropagation();
                            dispatch(
                              changeArchive(record.id, !record.archived)
                            );
                          }}
                        >
                          {record.archived ? "Unarchive" : "Archive"}
                          <ArchiveIcon icon={faArchive} />
                        </Archive>

                        {(key === "STARTED" ||
                          key === "AWAITING_CONTRACTOR") && (
                          <Delete
                            onClick={(e) => {
                              e.stopPropagation();

                              deleteAssessment(record.engagement_id);
                            }}
                          >
                            <DeleteIcon icon={faTrashAlt} />
                          </Delete>
                        )}

                        <div className="left">
                          <StyledFontAwesomeIcon
                            className={record.flag ? "flag" : ""}
                            icon={
                              record.contractor_id === -1
                                ? faClone
                                : faUserFriends
                            }
                          />
                        </div>
                        <div className="right">
                          <div className="reference">
                            <StyledFontAwesomeIcon icon={statuses[key].icon} />
                            {record.reference}
                          </div>
                          <h3 className={record.flag ? "flag" : ""}>
                            {record.contractor_name}
                          </h3>
                          {record.flag && (
                            <FlagText>Dispute response required soon</FlagText>
                          )}
                          {record.result && (
                            <Result className={getRiskClassName(record.result)}>
                              {getDisplayName(
                                record.result,
                                record.validation ? "SHORT" : "FULL"
                              )}
                            </Result>
                          )}
                          {record.validation && (
                            <Result className="generic">Validation</Result>
                          )}
                        </div>
                      </Card>
                    ))}
                  </div>
                </Column>
              ))}
            </Columns>
          </ColumnsWrapper>
        </Wrap>
      )}
    </Main>
  );
}

export default withRouter(Dashboard);
