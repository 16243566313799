import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { orderBy } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { StyledDataTable, darkTheme } from "../components/TableStyles";
import {
  getLoading,
  getContractors,
  getStandaloneSummary,
  getTotalRows,
  getCurrentStandalonePage,
  getStandalonePerPage,
  getStandaloneSearchTerm,
} from "../store/contractors/selectors";
import {
  loadContractors,
  requestStandaloneSummary,
  setPageStandalone,
  setPerPageStandalone,
  setSearchTermStandalone,
} from "../store/contractors/actions";
import { loadClients } from "../store/clients/actions";
import { TitleArea, ContentArea, Empty, Wrapper } from "../components/Elements";
import noContractors from "../assets/images/no-contractors.svg";
import noResults from "../assets/images/no-search-results.svg";
import Button from "../components/Button";
import Loader from "../components/Loader";
import FormField from "../components/FormField";
import { mainColor } from "../styles/colors";

var timeout;

var debounce = function(func, delay) {
  clearTimeout(timeout);

  timeout = setTimeout(func, delay);
};

const TableWrapper = styled.div`
  margin: 0px 20px;
  background-color: #fff;
  padding: 30px 20px;
  border-radius: 4px;
  flex: 1;
`;

const Name = styled.div`
  .initials {
    border-radius: 50%;
    background-color: ${mainColor};
    color: #fff;
    text-align: center;
    line-height: 30px;
    width: 30px;
    height: 30px;
    margin-right: 10px;
    display: inline-block;
  }
`;

const Results = styled.div`
  display: flex;
  margin: 20px 10px;
`;

const Result = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 6px;
  flex: 1;
  margin: 10px;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
`;

const Total = styled.div`
  font-size: 40px;
`;

function StandaloneContractors({ history, match }) {
  const dispatch = useDispatch();

  const loading = useSelector(getLoading);
  const contractors = useSelector(getContractors);
  const summary = useSelector(getStandaloneSummary);
  const totalRows = useSelector(getTotalRows);
  const perPage = useSelector(getStandalonePerPage);
  const page = useSelector(getCurrentStandalonePage) + 1;
  const searchTerm = useSelector(getStandaloneSearchTerm);

  let {
    totalAssessments,
    totalPaidAssessments,
    totalRevenue,
    totalSubscribed,
    totalSubscribedRevenue,
  } = summary;

  useEffect(() => {
    dispatch(requestStandaloneSummary());
    dispatch(loadContractors(searchTerm, null, true, page, perPage));
  }, []);

  useEffect(() => {
    dispatch(loadContractors(searchTerm, null, true, 1, perPage));
    setLocalSearchTerm(searchTerm);
  }, [searchTerm]);

  const movePage = async (page) => {
    if (sortDirectionState && columnState) {
      dispatch(loadContractors(null, null, true, page, perPage, sortDirectionState, columnState));
    } else {
      dispatch(loadContractors(null, null, true, page, perPage));
    }
    dispatch(setPageStandalone(page - 1));
  };

  const changePerPage = async (newPerPage, page) => {
    dispatch(loadContractors(null, null, true, page, newPerPage));
    dispatch(setPerPageStandalone(newPerPage));
    dispatch(setPageStandalone(page - 1));
  };

  const [searching, setSearching] = useState(false);
  const [sortDirectionState, setSortDirection] = useState(null);
  const [columnState, setColumn] = useState(null);
  const [localSearchTerm, setLocalSearchTerm] = useState(null);

  const NameField = ({ row }) => (
    <Name>
      <span className="initials">
        {row.first_name[0]}
        {row.last_name[0]}
      </span>
      {row.first_name} {row.last_name}
    </Name>
  );

  const renderTable = () => {
    const columns = [
      {
        name: "Name",
        selector: "last_name",
        sortable: true,
        cell: (row) => <NameField row={row} />,
      },
      {
        name: "Ltd Company Name",
        selector: "ltd_company_name",
        sortable: true,
      },
      {
        name: "Email",
        selector: "email",
        sortable: true,
      },
    ];

    const handleRowClicked = (row) =>
      history.push(`/main/contractors/edit/${row.id}`);

    return (
      <TableWrapper>
        <StyledDataTable
          progressPending={loading}
          pagination
          paginationServer
          customTheme={darkTheme}
          columns={columns}
          data={contractors}
          fixedHeader
          highlightOnHover
          fixedHeaderScrollHeight="100%"
          title="All Contractors"
          onRowClicked={handleRowClicked}
          paginationDefaultPage={page}
          paginationPerPage={perPage}
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={changePerPage}
          onChangePage={movePage}
          sortServer
          onSort={handleSort}
        />
      </TableWrapper>
    );
  };
  const handleSort = async (column, sortDirection) => {
    // Set the direction and column as this is not passed in page change for the react-data-table-component package
    setSortDirection(sortDirection);
    setColumn(column.selector);
    dispatch(loadContractors(null, null, true, page, perPage, sortDirection, column.selector));
  };
  const preExecuteSearch = (value) => {
    setLocalSearchTerm(value);
    debounce(() => executeSearch(value), 500);
   
  };

  const executeSearch = value => {
    setSearching(!!value);
    dispatch(setSearchTermStandalone(value));
  }

  return (
    <Wrapper>
      <TitleArea>
        <div className="title">
          {" "}
          <h1>One-off Contractors</h1>
          <FormField
            placeholder="Search"
            type="text"
            changeMonitor={(value) => preExecuteSearch(value)}
            className="search"
            value={localSearchTerm}
          />
        </div>
        <div className="controls"></div>
      </TitleArea>
      <ContentArea>
        <Results>
          <Result>
            <Title>Total Assessments</Title>
            <Total>{totalAssessments}</Total>
          </Result>
          <Result>
            <Title>Total Paid</Title>
            <Total>{totalPaidAssessments}</Total>
          </Result>
          <Result>
            <Title>Total One-off Revenue</Title>
            <Total>£{totalRevenue}</Total>
          </Result>
          <Result>
            <Title>Total Subscribed</Title>
            <Total>{totalSubscribed}</Total>
          </Result>
          <Result>
            <Title>Total Subscribed Revenue</Title>
            <Total>{totalSubscribedRevenue}</Total>
          </Result>
        </Results>
        {loading && !contractors && !contractors.length ? (
          <Loader />
        ) : contractors && contractors.length ? (
          renderTable()
        ) : (
          <div>
            {!searching ? (
              <Empty>
                <img src={noContractors} />
                <h2>There aren't any contractors yet</h2>
              </Empty>
            ) : (
              <Empty>
                <img src={noResults} />
                <h2>No search results</h2>
                <p>Try searching for something else</p>
              </Empty>
            )}
          </div>
        )}
      </ContentArea>
    </Wrapper>
  );
}

export default StandaloneContractors;
