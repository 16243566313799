import React, { useState, useContext } from "react";
import { ColorContext } from "../components/Bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import {
  getLoginError,
  getUser,
  getRealm,
  getHasContractorRole,
  getHasCSTRole,
} from "../store/users/selectors";
import { login } from "../store/users/actions";
import Validation from "../components/FormValidation";
import styled from "styled-components";
import Header from "../components/Header";
import Form from "../components/Form";
import FormField from "../components/FormField";
import FormRow from "../components/FormRow";
import Button from "../components/Button";
import { device } from "../components/Devices";
import { mainColor } from "../styles/colors";
import { Link } from "react-router-dom";
import {
  Content,
  FormLayoutWrapper,
  Success,
  Errors,
} from "../components/Elements";

const H1 = styled.h1`
  font-family: Blinker;
  margin: 0;
  color: #fff;
  font-size: 40px;
  font-weight: 100;
  text-align: center;
  margin-top: 0px;
  @media ${device.laptop} {
    margin-top: 100px;
  }
`;

const H2 = styled.h2`
  font-family: Blinker;
  font-weight: 100;
  color: #888;
  font-size: 20px;
  margin: 0;
  margin-bottom: 20px;
`;

const ALink = styled(Link)`
  color: ${(props) => props.mainColor};
`;

function Login({ location }) {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const mainColor = useContext(ColorContext);
  const [validation, setValidation] = useState({});
  const dispatch = useDispatch();
  const loginError = useSelector(getLoginError);
  const user = useSelector(getUser);
  const realm = useSelector(getRealm);
  const hasContractorRole = useSelector(getHasContractorRole);
  const hasCSTRole = useSelector(getHasCSTRole);
  const { referrer } = location.state || { referrer: "/main/dashboard" };
  const [loading, setLoading] = useState(false)

  const formValidation = new Validation(validation, setValidation);

  formValidation.setRules({
    email: [
      {
        canned: "email",
      },
    ],
  });

  const updateFormData = (field, value) => {
    setFormData({ ...formData, [field]: value });
    formValidation.validate(field, value, formData);
  };

  const sendForm = async (e) => {
    e.preventDefault();
    
    const validationErrors = formValidation.validateForm(formData);

    if (validationErrors) {
      return;
    }
    setLoading(true);

    await dispatch(login(formData.email, formData.password, realm));
    setLoading(false);
  };

  if (user && !hasContractorRole && !hasCSTRole) {
    return <Redirect to={referrer} />;
  } else if (hasCSTRole) {
    return <Redirect to="/main/settings/users" />;
  } else if (user && hasContractorRole) {
    return <Redirect to="/contractor-assessment/1" />;
  }

  return (
    <div>
      <Header />
      <Content mainColor={mainColor}>
        <H1>Login for IR35 Assessments</H1>

        <FormLayoutWrapper>
          <Form>
            <H2>Your Details</H2>
            <FormRow cols={1}>
              <FormField
                name="email"
                label="Your email address"
                type="text"
                changeMonitor={(value) => updateFormData("email", value)}
                value={formData.email}
                validation={validation.email}
              />
              <FormField
                name="password"
                label="Password"
                type="password"
                changeMonitor={(value) => updateFormData("password", value)}
                value={formData.password}
                validation={validation.password}
              />
            </FormRow>
            <FormRow cols={1}>
              <Button loading={loading} loadingText="Logging in..." onClick={sendForm}>Login</Button>
            </FormRow>

            {user ? (
              <Success>
                <h5>You're logged in, we won't show this normally</h5>
              </Success>
            ) : null}
            {loginError ? (
              <Errors>
                <h5>
                  {loginError === "LOCKED"
                    ? "Your account has been locked due to too many failed login attempts.  Please go through the 'forgot password' process to reset your account."
                    : "Incorrect username/password - please try again"}
                </h5>
              </Errors>
            ) : null}
            <ALink to="/forgot-password" mainColor={mainColor}>
              Forgot Password?
            </ALink>
          </Form>
        </FormLayoutWrapper>
      </Content>
    </div>
  );
}

export default Login;
