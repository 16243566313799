import React, { useEffect, useState, useRef } from "react";
import { debounce } from "lodash/fp";
import Infinite from "react-infinite";
import styled from "styled-components";
import { faCheck, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ComboWrapper = styled.div`
  position: relative;
  max-width: 300px;
`;

const Input = styled.input`
  width: 100%;
  border: 1px solid #ccc;
  font-size: 16px;
  padding: 10px 10px;

  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 0px;
  background-color: #fff;
  outline: none;
  max-width: 300px;
`;

const ComboOptionsElement = styled.div`
  background-color: #fff;
  z-index: 100000;
  position: absolute;
  top: 40px;
  left: 0;
  max-height: 500px;
  width: 400px;
  overflow: auto;
  box-shadow: 0 9px 19px -9px #777
  border: 1px solid #ccc;
  padding-left: 10px;
  padding-top: 2px;
`;

const ComboOptionElement = styled.div`
  cursor: pointer;
  padding: 2px 4px;
  position: relative;
  &:hover {
    background-color: #eee;
    color: #000;
  }

  .selection-marker {
    display: inline-block;
    position: absolute;
    left: -14px;
    top: 0;
  }

  &.hover .selection-marker {
    color: #000;
  }
  margin: 2px 4px;
  font-size: 18px;

  &.match {
    background-color: #fff5c4;
  }
`;

const OptionLevel = styled.div`
  ${(props) => `padding-left: ${props.level * 10 + 5}px;`}
`;

const SelectedIcon = styled(FontAwesomeIcon)`
color: #000
  font-size: 12px;
  line-height: 18px;
  margin-right: 5px;
`;

const DownArrow = styled(FontAwesomeIcon)`
  position: absolute;
  color: #000;
  top: 12px;
  right: 12px;
`;

const NoResults = styled.div`
  padding: 10px 0;
`;

function useOutsideAlerter(refs, fn) {
  useEffect(() => {
    function handleClickOutside(event) {
      const mapped = refs.map((ref) => {
        if (ref.current && !ref.current.contains(event.target)) {
          return true;
        }
        return false;
      });

      let isOutside = !mapped.includes(false);

      if (isOutside) {
        fn && fn();
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [refs]);
}

const findInTree = (value, options) => {
  let match;

  options.forEach((option) => {
    if (Number(option.value) === Number(value)) {
      match = option;
    } else if (option.children) {
      const childMatch = findInTree(value, option.children);
      if (childMatch) {
        match = childMatch;
      }
    }
  });

  return match;
};

const findByIndex = (options, index) => {
  let match;

  options.forEach((option) => {
    if (option.index === index) {
      match = option.value;
    } else if (option.children) {
      const childMatch = findByIndex(option.children, index);
      if (childMatch) {
        match = childMatch;
      }
    }
  });

  return match;
};

const indexOptions = (options = []) => {
  let index = 0;

  const recurse = (level) => {
    return level.map((item) => {
      index += 1;
      return {
        ...item,
        index: index - 1,
        children: recurse(item.children),
      };
    });
  };

  return recurse(options);
};

const getTreeLength = (options) => {
  let total = 0;

  const count = (level) => {
    return level.forEach((item) => {
      total += 1;
      count(item.children);
    });
  };

  count(options);

  return total;
};

const filterRecursive = (options, search) => {
  const searchTerm = search.toLowerCase();

  const recurse = (level) => {
    return level
      .map((item) => ({ ...item }))
      .filter((item) => {
        if (item.name && item.name.toLowerCase().includes(searchTerm)) {
          return true;
        } else if (item.children && item.children.length) {
          const childResult = recurse(item.children);

          item.children = childResult;

          return childResult.length;
        }
        return false;
      });
  };

  return recurse(options);
};

const getHighlightedText = (text, highlight) => {
  const parts = text ? text.split(new RegExp(`(${highlight})`, "gi")) : [];

  return (
    <span>
      {" "}
      {parts.map((part, i) => (
        <span
          key={i}
          style={
            part.toLowerCase() === highlight.toLowerCase()
              ? { fontWeight: "bold" }
              : {}
          }
        >
          {part}
        </span>
      ))}{" "}
    </span>
  );
};

const ComboOption = React.forwardRef(
  ({ isSelected, onClick, option, searchVal }, ref) => {
    return (
      <ComboOptionElement ref={ref} onClick={() => onClick(option.value)}>
        {isSelected && (
          <div class="selection-marker">
            <SelectedIcon icon={faCheck} />
          </div>
        )}
        {getHighlightedText(option.name, searchVal)}
      </ComboOptionElement>
    );
  }
);

const ComboOptions = React.forwardRef(
  ({ options, value, onClick, searchVal }, ref) => {
    const selectedRef = useRef(null);

    useEffect(() => {
      if (selectedRef && selectedRef.current) {
        selectedRef.current.scrollIntoView({
          block: "center",
        });
      }
    }, []);

    const openMap = useState({[options[0].id]: true});

    const recursiveRender = (options, level) => {
      return (
        <OptionLevel level={level}>
          {options.map((option) => {
            const isSelected = Number(value) === Number(option.value);
            let items = [
              <ComboOption
                ref={isSelected ? selectedRef : null}
                onClick={() => onClick(option.value)}
                isSelected={isSelected}
                option={option}
                searchVal={searchVal}
              ></ComboOption>,
            ];

            if (option.children) {
              items.push(recursiveRender(option.children, level + 1));
            }

            return items;
          })}
        </OptionLevel>
      );
    };

    return (
      <ComboOptionsElement ref={ref}>
        {options && options.length ? (
          recursiveRender(options, 0)
        ) : (
          <NoResults>No results found</NoResults>
        )}
      </ComboOptionsElement>
    );
  }
);

function Combo({ options, onChange, className, value }) {
  const [selected, setSelected] = useState(null);
  const [showOptions, setShowOptions] = useState(false);
  const [search, setSearch] = useState("");
  const [activeOptions, setActiveOptions] = useState([]);
  const [placeholder, setPlaceholder] = useState([]);

  const inputRef = useRef(null);

  const onClickFn = (newValue) => {
    setShowOptions(false);
    onChange(newValue);
  };

  const runSearch = debounce(300, setSearch);

  const comboRef = useRef(null);
  useOutsideAlerter([inputRef, comboRef], () => setShowOptions(false));

  useEffect(() => {
    if (options && value) {
      setSelected(findInTree(value, options));
    }
    if (options) {
      setActiveOptions(options);
    }
  }, [value, options]);

  useEffect(() => {
    if (search) {
      setActiveOptions(filterRecursive(options, search));
    } else {
      setActiveOptions(options);
    }
  }, [search]);

  useEffect(() => {
    if (showOptions) {
      setPlaceholder("Search");
    } else {
      setPlaceholder(selected && selected.name);
    }
  }, [showOptions, selected]);

  return (
    <ComboWrapper className={className}>
      <DownArrow
        icon={faChevronDown}
        onClick={(e) => {
          setShowOptions(true);
        }}
      />
      <Input
        placeholder={placeholder}
        ref={inputRef}
        onClick={(e) => {
          setShowOptions(true);
          e.target.select();
        }}
        onChange={(e) => runSearch(e.target.value)}
        onBlur={(e) => (e.target.value = "")}
      ></Input>
      {showOptions && (
        <ComboOptions
          options={activeOptions}
          ref={comboRef}
          onClick={onClickFn}
          value={value}
          searchVal={search}
        />
      )}
    </ComboWrapper>
  );
}

export default Combo;
